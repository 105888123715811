<!--    <div class='Dept'></div>-->
<!--    部门管理-->
<template>
    <div class="Dept" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never" style="margin-top: 8px">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form
                @submit.native.prevent
                :inline="true"
                style="text-align: right"
                v-model="form"
                size="small"
                label-width="85px"
            >
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="机构组:"> {{ deptGroupName }} </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="机构名称">
                            <el-input v-model="form.search" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" v-if="$route.params.deptGroupType === deptTypeEnum.STORE">
                        <el-form-item label="营业执照">
                            <el-input placeholder="营业执照名称" v-model="form.deptLicenseName" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button type="primary" @click="handleQuery" size="small" v-if="hasPrivilege('menu.system.dept.open')"
                >查询
            </el-button>
            <el-button type="primary" @click="handleCreate" size="small" v-if="hasPrivilege('menu.system.dept.create')"
                >新建机构
            </el-button>
            <el-button type="primary" @click="handleExport" size="small" v-if="hasPrivilege('menu.system.dept.export')"
                >导出
            </el-button>
            <span style="float: right">
                <el-button
                    size="mini"
                    @click="showChangeReferrerDialog"
                    v-if="hasPrivilege('biz.member.update.batch.developMember')"
                    >变更推荐人</el-button
                >
                <el-button size="mini" @click="showCheckTableShowColumnDialog">表头</el-button>
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <check-table-show-column-dialog ref="checkTableShowColumnDialog" @checkTableShowColumn="setShowTableColumns" />
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                ref="table"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="500"
            >
                <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
                <el-table-column label="机构名称" width="170" prop="name" v-if="showColumn('name')" fixed="left" />
                <el-table-column
                    label="营业执照名称"
                    width="170"
                    prop="deptLicenseName"
                    v-if="$route.params.deptGroupType === deptTypeEnum.STORE && showColumn('deptLicenseName')"
                />
                <!--              -->
                <el-table-column label="类型" width="80" prop="type" v-if="showColumn('type')" key="type">
                    <template slot-scope="scope">
                        <span>{{ scope.row.type | deptType }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="省份"
                    width="120"
                    prop="provinceName"
                    v-if="showColumn('provinceName')"
                    sortable
                />
                <el-table-column label="地市" width="80" prop="cityName" v-if="showColumn('cityName')" sortable />
                <el-table-column
                    label="区县"
                    width="80"
                    prop="districtName"
                    v-if="showColumn('districtName')"
                    sortable
                />
                <el-table-column label="地址" width="160" prop="address" v-if="showColumn('address')" sortable />
                <el-table-column
                    label="联系人"
                    width="100"
                    prop="linkmanName"
                    v-if="showColumn('linkmanName')"
                    sortable
                />
                <el-table-column
                    label="联系电话"
                    width="100"
                    prop="linkmanTelephone"
                    v-if="showColumn('linkmanTelephone')"
                    sortable
                />
                <el-table-column label="创建人" width="100" prop="creator" v-if="showColumn('creator')" sortable />
                <el-table-column
                    label="创建时间"
                    width="150"
                    prop="createTime"
                    v-if="showColumn('createTime')"
                    sortable
                />

                <el-table-column
                    label="操作"
                    min-width="415"
                    header-align="center"
                    v-if="showColumn('operate')"
                    prop="operate"
                    key="operate"
                    fixed="right"
                >
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            type="warning"
                            @click="handleRowEdit(scope.row)"
                            v-if="hasPrivilege('menu.system.dept.edit')"
                            >编辑
                        </el-button>
                        <el-button
                            size="mini"
                            type="danger"
                            @click="handleRowDelete(scope.row.code)"
                            v-if="hasPrivilege('menu.system.dept.delete')"
                            >删除
                        </el-button>
                        <el-button
                            size="mini"
                            type="success"
                            @click="showChangeDeptStaffDialog(scope.row)"
                            v-if="hasPrivilege('menu.system.dept.edit')"
                            >人员设置
                        </el-button>
                        <el-button
                            size="mini"
                            type="success"
                            @click="handleRowSettings(scope.row)"
                            v-if="hasPrivilege('biz.system.deptPrivilege.query')"
                            >权限设置
                        </el-button>
                        <el-button
                            size="mini"
                            type="danger"
                            @click="showDeptMoveDialog(scope.row)"
                            v-if="hasPrivilege('menu.system.dept.edit')"
                            >迁移机构</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
        <el-dialog title="机构权限" :visible.sync="dialogVisible">
            <el-card>
                <el-form>
                    <el-form-item>
                        <el-checkbox-group v-model="setting">
                            <el-checkbox :label="dp.code" v-for="dp in deptPrivileges" :key="dp.code"
                                >{{ dp.description }}
                            </el-checkbox>
                            <br />
                        </el-checkbox-group>
                    </el-form-item>
                </el-form>
            </el-card>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false" size="small">取 消</el-button>
                <el-button type="primary" @click="handleRowSettingOk" size="small">确 定</el-button>
            </div>
        </el-dialog>
        <ChangeDeptStaff ref="changeDeptStaff" @flush="handleQuery"></ChangeDeptStaff>
        <ChangeReferrer ref="changeReferrer"></ChangeReferrer>
        <DeptMove ref="deptMove" @saveOk="handleQuery"></DeptMove>
    </div>
</template>

<script>
import Util from 'js/Util';
import UrlUtils from 'js/UrlUtils';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';
import ChangeDeptStaff from 'views/menu/system/group/ChangeDeptStaff';
import ChangeReferrer from 'views/menu/system/group/ChangeReferrer';
import DeptMove from 'views/menu/system/group/DeptMove';
import { DeptTypeEnum } from 'js/DeptCommon';

export default {
    name: 'DeptSetting',
    components: { CheckTableShowColumnDialog, ChangeDeptStaff, ChangeReferrer, DeptMove },
    mixins: [CheckTableShowColumn],
    /* props: {
        deptGroupType: { type: Number, default: null },
        deptGroupName: { type: String, default: '' },
        deptGroupCode: { type: String, default: '' },
    },*/
    data() {
        return {
            deptTypeEnum: DeptTypeEnum,
            deptGroupType: null,
            deptGroupName: '',
            form: {
                search: '',
                deptLicenseName: '',
                type: '',
                deptGroupCode: '',
            },

            settingCode: '',
            //已选定的部门权限
            setting: [],
            settingBackUp: [],
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            loading: false,
            dialogVisible: false,
            tableData: [],
            deptPrivileges: {},
            url: {
                queryExtend: '/system/dept/page',
                disable: '/system/dept/disable',
                privilege: '/system/deptPrivilege',
                bindPrivilege: '/system/deptPrivilege/bindPrivilege',
            },
        };
    },
    watch: {
        deptGroupCode: {
            handler(n, o) {
                this.form.deptGroupCode = n;
                this.tableData = [];
            },
            immediate: true,
        },
    },
    mounted() {
        //将mounted中的逻辑挪到activated中
    },
    activated() {
        this.executeQueryIfNeedReload(this.handleQuery, this.handleReloadAll);
    },
    methods: {
        showDeptMoveDialog(row) {
            //迁移机构窗口
            this.$refs.deptMove.show(row.code, row.deptGroupCode, row.type);
        },
        showChangeReferrerDialog() {
            //打开变更联系人窗口
            this.$refs.changeReferrer.show(this.deptGroupType);
        },
        showChangeDeptStaffDialog(row) {
            //打开部门人员窗口
            this.$refs.changeDeptStaff.show(row, this.form.deptGroupCode);
        },
        handleReloadAll() {
            //接收上一页面传递的机构组信息
            this.form.deptGroupCode = this.$route.params.deptGroupCode;
            this.deptGroupName = this.$route.params.deptGroupName;
            this.deptGroupType = this.$route.params.deptGroupType;
            this.handleQuery();
        },
        handleQuery() {
            const _params = { params: this.form };
            Util.queryTable(this, this.url.queryExtend, _params, (rst) => {
                this.tableData = rst.data;
            });
        },
        handleCreate() {
            Util.nameJump(
                this,
                'menu.system.deptGroup.deptSetting.create',
                ['系统管理', '机构组管理', '机构设置', '新建机构'],
                {
                    deptGroupCode: this.form.deptGroupCode,
                    deptGroupType: this.deptGroupType,
                    deptGroupName: this.deptGroupName,
                    //是否需要重新加载所有数据flag
                    reloadAllFlag: true,
                }
            );
        },
        handleExport() {
            UrlUtils.Export(this, '机构', '/system/dept/export', this.form);
        },
        handleRowDelete(code) {
            const _delete = () => {
                return this.$http.delete(this.url.disable, { params: { code: code } });
            };
            UrlUtils._commonDml(this, {
                message: '是否删除该信息,删除后不可恢复,是否确认删除?',
                methods: _delete,
            });
        },
        handleRowEdit(row) {
            Util.nameJump(
                this,
                'menu.system.deptGroup.deptSetting.edit',
                ['系统管理', '机构组管理', '机构设置', '编辑机构'],
                {
                    form: row,
                    code: row.code,
                    deptGroupName: this.deptGroupName,
                }
            );
        },
        handleRowSettings(row) {
            this.settingCode = row.code;

            UrlUtils.QueryRemote(this, this.url.privilege + '/' + row.code, (rst) => {
                this.deptPrivileges = rst;
                this.setting = rst.filter((r) => r.checked).map((r) => r.code);
                this.settingBackUp = JSON.parse(JSON.stringify(this.setting));
                this.dialogVisible = true;
            });
        },
        handleRowSettingOk() {
            UrlUtils.PostRemote(
                this,
                this.url.bindPrivilege,
                {
                    deptCode: this.settingCode,
                    checked: this.setting,
                    unchecked: this.settingBackUp.filter((r) => !this.setting.includes(r)),
                },
                {
                    transformRequest: [
                        (target) => {
                            return (
                                'deptCode=' +
                                target.deptCode +
                                '&' +
                                target.checked.map((t) => 'checked=' + t).join('&')
                            );
                        },
                    ],
                },
                () => {
                    this.$message.success('保存成功');
                    this.dialogVisible = false;
                }
            );
        },
    },
};
</script>

<style scoped>
.Dept .el-form-item {
    margin-bottom: 0;
}
</style>
