<template>
    <div>
        <el-dialog
            title="迁移机构"
            :visible.sync="visible"
            style="width: 900px; margin-left: 20%; height: 700px"
            destroy-on-close
        >
            <br />
            <el-row style="margin-left: 30px">
                机构组名称 ：
                <el-select v-model="checkedDeptGroupCode" placeholder="请选择">
                    <el-option v-for="item in deptGroupArr" :key="item.code" :label="item.name" :value="item.code">
                    </el-option>
                </el-select>
            </el-row>
            <br />
            <el-row style="margin-left: 30px; color: red">
                迁移机构时需要将本机构除登录人之外的人员清空，迁移机构将导致本机构组无法查询到此机构的数据，请小心操作
            </el-row>
            <br />
            <el-row>
                <el-button @click="visible = false" size="small" style="margin-left: 200px">取 消</el-button>
                <el-button type="primary" size="small" @click="handleSave" :disabled="checkedDeptGroupCode === ''"
                    >确 定</el-button
                >
            </el-row>
        </el-dialog>
    </div>
</template>

<script>
import Util from 'js/Util';
import UrlUtils from 'js/UrlUtils';

export default {
    name: 'DeptMove',
    components: {},
    data() {
        return {
            visible: false /*弹窗显示状态*/,
            deptType: '',
            deptGroupArr: [],
            checkedDeptGroupCode: '',
            deptCode: '',
            deptGroupCode: '',
            deptStaffs: [],
            url: {
                page: '/system/deptGroup/page',
                changeDeptGroupOrLinkman: '/system/dept/changeDeptGroup',
                allDeptGroupUrl: '/system/deptGroup/getAllDeptGroup',
            },
        };
    },
    methods: {
        show(deptCode, deptGroupCode, deptType) {
            this.deptType = deptType;
            //清空选择框
            this.checkedDeptGroupCode = '';
            this.deptCode = deptCode;
            this.deptGroupCode = deptGroupCode;
            this.handleQuery();
        },
        handleSave() {
            //机构 迁移机构组
            UrlUtils.PatchRemote(
                this,
                this.url.changeDeptGroupOrLinkman,
                {
                    deptCode: this.deptCode,
                    targetDeptGroupCode: this.checkedDeptGroupCode,
                },
                null,
                (res) => {
                    this.visible = false;
                    this.$message.success('保存成功');
                    //刷新父组件数据
                    this.$emit('saveOk');
                }
            );
        },
        handleQuery() {
            //查询机构组 上限300
            Util.queryTable(this, this.url.allDeptGroupUrl, null, (res) => {
                this.deptGroupArr = res.data.filter((deptGroup) => {
                    return deptGroup.code !== this.deptGroupCode && this.deptType === deptGroup.deptType;
                });
                this.visible = true;
            });
        },
    },
};
</script>
