<template>
    <div class="PickUpDeptStaff">
        <ef-dialog title="查询机构组全部人员" :visible.sync="visible" width="80%" top="60px" @confirm="handleCommit">
            <el-card shadow="never" style="margin-top: 8px">
                <el-form
                    @submit.native.prevent
                    :inline="true"
                    style="text-align: right"
                    v-model="form"
                    ref="queryForm"
                    size="small"
                >
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="关键字">
                                <el-input type="text" v-model="form.search" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item>
                                <el-button type="primary" @click="handleQuery">查询</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </el-card>

            <el-card shadow="never" style="margin-top: 8px" align="left">
                <div style="height: 500px; width: 100%">
                    <el-table
                        border
                        stripe
                        style="width: 100%"
                        size="mini"
                        max-height="500px"
                        :highlight-current-row="true"
                        ref="pickUpDeptStaffTable"
                        :data="tableData"
                        @select="handleSelected"
                    >
                        <el-table-column type="selection" :selectable="selectable"></el-table-column>
                        <el-table-column type="index" label="序号"></el-table-column>
                        <el-table-column property="realName" label="姓名"></el-table-column>
                        <el-table-column property="mobile" label="手机号"></el-table-column>
                        <el-table-column label="绑定角色" min-width="150">
                            <template slot-scope="scope">
                                <span>{{ scope.row.roles | roles }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="deptName" label="归属部门"></el-table-column>
                    </el-table>
                </div>
                <ef-pagination
                    v-if="visible"
                    :total="total"
                    :default-limit="form.limit"
                    :pageSizes="[10, 20, 50, 100, 150]"
                    @pageChange="pageChange"
                />
            </el-card>
        </ef-dialog>
    </div>
</template>

<script>
import EfDialog from 'components/EfDialog';
import EfPagination from 'components/EfPagination';
import Util from 'js/Util';

export default {
    name: 'PickUpDeptStaff',
    components: { EfDialog, EfPagination },
    data() {
        return {
            form: {
                /*列表查询请求参数*/
                search: '',
                deptGroupCode: '',
                staffType: '',
                page: 1,
                limit: 20,
            },
            visible: false,
            tableData: [] /*查询的全部数据*/,
            checkDisabledDeptStaffArr: [] /*禁止进行check操作的员工列表*/,
            multiCheckFlag: false /*表单操作类型 true为提交多选数据 false为提交单选单选*/,
            total: 0,
            url: {
                page: '/system/staff/queryExtend',
            },
        };
    },
    filters: {
        roles(roles) {
            return roles ? roles.map((r) => r.name).join(',') : '';
        },
    },
    methods: {
        //是否可以进行勾选操作，存在于禁止check数组中的数据禁止进行check操作
        selectable(row) {
            const index = this.checkDisabledDeptStaffArr.findIndex((item) => {
                return row.code === item.code;
            });
            return index < 0;
        },
        checkExistData() {
            this.$nextTick(() => {
                //勾选已存在的数据
                this.checkDisabledDeptStaffArr.forEach((item) => {
                    const row = this.tableData.find((row) => {
                        return row.code === item.code;
                    });
                    //dom渲染结束勾选
                    this.$refs.pickUpDeptStaffTable.toggleRowSelection(row, true);
                });
            });
        },
        handleQuery() {
            //进入弹窗初始化
            Util.queryTable(this, this.url.page, { params: this.form }, (res) => {
                this.tableData = res.data;
                this.total = res.count;
                //数据加载完成 勾选已有数据
                this.checkExistData();
            });
        },
        show(deptGroupCode, checkDisabledDeptStaffArr, multiCheckFlag) {
            //调用方法显示弹窗
            this.multiCheckFlag = multiCheckFlag;
            this.form.deptGroupCode = deptGroupCode;
            this.checkDisabledDeptStaffArr = checkDisabledDeptStaffArr;
            this.visible = true;
            this.handleQuery();
        },
        pageChange(page, limit) {
            this.form.page = page;
            this.form.limit = limit;
            this.handleQuery();
        },
        handleSelected(selection, row) {
            //处理点击选择按钮的操作
            if (!this.multiCheckFlag) {
                //单选操作类型，清空已勾选数据，并设置当前row勾选
                this.$refs.pickUpDeptStaffTable.clearSelection();
                this.$refs.pickUpDeptStaffTable.toggleRowSelection(row, true);
            }
        },
        handleCommit() {
            //重复数据过滤
            const queryData = this.$refs.pickUpDeptStaffTable.selection.filter((row) => {
                if (!row) {
                    return false;
                }
                return !this.checkDisabledDeptStaffArr.find((item) => {
                    return item.code === row.code;
                });
            });
            //调用父组件方法对选择数据进行处理
            this.$emit('query', queryData, this.multiCheckFlag);
            this.visible = false;
        },
    },
};
</script>
<style scoped>
/deep/.el-table__header-wrapper .el-checkbox {
    display: none;
}
</style>
