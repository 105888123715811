<template>
    <el-dialog title="变更推荐人" :visible.sync="visible" width="55%" top="100px" :close-on-click-modal="false">
        <span class="pClass">如新变更员工未与（朗勤心选APP）合作,则此会员将归八号地</span>
        <el-card shadow="never">
            <el-row>
                <el-col :span="1">
                    <span class="spanClass">将</span>
                </el-col>
                <el-col :span="4">
                    <el-input
                        v-model="changeReferrerFrom.staffName"
                        style="width: 90%"
                        size="small"
                        type="text"
                        readonly
                    >
                        <i class="el-icon-search qing-icon" slot="suffix" @click="choiceStaffButton('old')" />
                        <i class="el-icon-circle-close qing-icon" slot="suffix" @click="handleIconClick('old')" />
                    </el-input>
                </el-col>
                <el-col :span="10">
                    <span class="spanClass">发展的会员在心选app和八号地综合零售系统中变更给</span>
                </el-col>
                <el-col :span="4">
                    <el-input
                        v-model="changeReferrerFrom.newStaffName"
                        style="width: 90%"
                        size="small"
                        type="text"
                        readonly
                    >
                        <i class="el-icon-search qing-icon" slot="suffix" @click="choiceStaffButton('new')" />
                        <i class="el-icon-circle-close qing-icon" slot="suffix" @click="handleIconClick('new')" />
                    </el-input>
                </el-col>
                <pick-up-staff ref="oldPickUpStaffTable" @ok="oldChoiceStaff" />
                <pick-up-staff ref="newPickUpStaffTable" @ok="newChoiceStaff" />
            </el-row>
        </el-card>
        <div slot="footer" class="dialog-footer">
            <el-button @click="visible = false" size="small">取 消</el-button>
            <el-button type="primary" @click="handleChangeReferrer" size="small">确 定</el-button>
        </div>
    </el-dialog>
</template>

<script>
import UrlUtils from 'js/UrlUtils';
import PickUpStaff from '../../../../views/components/PickUpStaff';
export default {
    name: 'ChangeReferrer',
    components: { PickUpStaff },
    data() {
        return {
            visible: false,
            deptType: '',
            changeReferrerFrom: {
                staffName: '',
                staffCode: '',
                newStaffName: '',
                newStaffCode: '',
            },
            url: {
                changeReferrer: '/system/staff/changeReferrer',
            },
        };
    },
    methods: {
        show(deptType) {
            this.changeReferrerFrom.staffName = '';
            this.changeReferrerFrom.staffCode = '';
            this.changeReferrerFrom.newStaffName = '';
            this.changeReferrerFrom.newStaffCode = '';
            this.deptType = deptType;
            this.visible = true;
        },
        choiceStaffButton(val) {
            const extendParams = {
                deptType: this.deptType,
                selectionChangeType: 'one',
                search: '',
                staffType: '',
            };
            if (val === 'old') {
                this.$refs.oldPickUpStaffTable.show(extendParams);
            } else {
                this.$refs.newPickUpStaffTable.show(extendParams);
            }
        },
        handleIconClick(val) {
            if (val === 'old') {
                this.changeReferrerFrom.staffCode = '';
                this.changeReferrerFrom.staffName = '';
            } else {
                this.changeReferrerFrom.newStaffCode = '';
                this.changeReferrerFrom.newStaffName = '';
            }
        },
        oldChoiceStaff(val) {
            this.changeReferrerFrom.staffCode = val[0].code;
            this.changeReferrerFrom.staffName = val[0].realName;
        },
        newChoiceStaff(val) {
            this.changeReferrerFrom.newStaffCode = val[0].code;
            this.changeReferrerFrom.newStaffName = val[0].realName;
        },
        handleChangeReferrer() {
            if (this.changeReferrerFrom.staffCode !== '') {
                let saveMessage;
                if (this.changeReferrerFrom.newStaffCode === '' || !this.changeReferrerFrom.newStaffCode) {
                    saveMessage =
                        '如果第二个选择框不选，八号地零售系统的会员推荐人将设置为空，心选App中的会员将归还给八号地！！';
                } else {
                    saveMessage =
                        '<' +
                        this.changeReferrerFrom.staffName +
                        '>发展的会员将变更给<' +
                        this.changeReferrerFrom.newStaffName +
                        '>';
                }
                const _p = {
                    staffCode: this.changeReferrerFrom.staffCode,
                    message: saveMessage,
                    assignStaffCode: this.changeReferrerFrom.newStaffCode,
                };
                UrlUtils.PatchRemote(this, this.url.changeReferrer, _p, null, () => {
                    this.$message.success('保存成功');
                    this.visible = false;
                });
            } else {
                this.$message.warning('要变更那个员工?请在第一个框中勾选');
            }
        },
    },
};
</script>

<style scoped>
.pClass {
    color: red;
    opacity: 0.7;
    font-size: 120%;
    width: auto;
}

.spanClass {
    font-size: 120%;
}

.qing-icon {
    padding-top: 5px;
    padding-right: 2px;
    font-size: 180%;
}
</style>
