<template>
    <div>
        <el-dialog :visible.sync="visible" style="width: 1500px; margin-left: 180px" destroy-on-close>
            <div>
                机构联系人：<el-button
                    size="mini"
                    style="margin-left: 5px"
                    type="success"
                    @click="queryDeptGroupAllStaffWindow(false)"
                    >设置</el-button
                >
            </div>
            <el-table :data="deptLinkmanArr" :show-header="false">
                <el-table-column property="linkmanName" width="130"></el-table-column>
                <el-table-column property="linkmanTelephone" width="150"></el-table-column>
                <el-table-column
                    ><el-button type="text" @click="handlePatchDeptLinkman(null)">删除</el-button></el-table-column
                >
            </el-table>
            <br />
            <br />
            <div>
                机构员工：
                <el-button
                    size="mini"
                    style="margin-left: 14px"
                    type="success"
                    @click="queryDeptGroupAllStaffWindow(true)"
                    >设置</el-button
                >
            </div>
            <el-table :data="deptStaffs" :show-header="false" max-height="200">
                <el-table-column property="realName" width="130"></el-table-column>
                <el-table-column property="mobile" width="150"></el-table-column>
                <el-table-column
                    ><template slot-scope="scope"
                        ><el-button type="text" @click="deleteDeptStaff(scope.row)">删除</el-button></template
                    ></el-table-column
                >
            </el-table>
        </el-dialog>
        <PickUpDeptStaff ref="pickUpDeptStaff" @query="handlerSave"></PickUpDeptStaff>
    </div>
</template>

<script>
import UrlUtils from 'js/UrlUtils';
import PickUpDeptStaff from 'components/PickUpDeptStaff';

export default {
    name: 'ChangeDeptStaff',
    components: { PickUpDeptStaff },
    data() {
        return {
            visible: false /*部门人员设置弹窗显示状态*/,
            deptStaffs: [] /*当前已有的部门人员*/,
            deptCheckedRow: {} /*当前选中行*/,
            deptLinkmanArr: [] /*部门联系人*/,
            deptGroupCode: '' /*机构组code*/,
            url: {
                deptGroupDepartmentRelatedStaffList: '/system/staff/deptGroupDepartmentRelatedStaffList',
                patch: '/system/dept/patch_c/',
                allotStaff: '/system/dept/allotStaff',
            },
        };
    },
    methods: {
        deleteDeptStaff(row) {
            if (row.type == 0) {
                const param = {
                    deptCode: this.deptCheckedRow.code,
                    delStaffCodes: row.code,
                    delStaffTypes: row.type,
                    cleanFriendsFlag: 0,
                };
                UrlUtils.PatchRemote(this, this.url.allotStaff, param, null, () => {
                    this.$message.success('操作成功');
                    this.handleQueryRelatedStaff();
                });
            } else if (row.type == 1) {
                this.$confirm('员工更换机构后，请选择该员工发展的商友去向', '商友迁移', {
                    distinguishCancelAndClose: true,
                    confirmButtonText: '归还机构',
                    cancelButtonText: '跟随员工',
                    type: 'warning',
                })
                    .then(() => {
                        const param = {
                            deptCode: this.deptCheckedRow.code,
                            delStaffCodes: row.code,
                            delStaffTypes: row.type,
                            cleanFriendsFlag: 1,
                        };
                        UrlUtils.PatchRemote(this, this.url.allotStaff, param, null, () => {
                            this.$message.success('操作成功');
                            this.handleQueryRelatedStaff();
                        });
                    })
                    .catch((action) => {
                        if (action == 'cancel') {
                            const param = {
                                deptCode: this.deptCheckedRow.code,
                                delStaffCodes: row.code,
                                delStaffTypes: row.type,
                                cleanFriendsFlag: 0,
                            };
                            UrlUtils.PatchRemote(this, this.url.allotStaff, param, null, () => {
                                this.$message.success('操作成功');
                                this.handleQueryRelatedStaff();
                            });
                        }
                    });
            }
        },
        handlerSave(selectedStaffArr = [], multiCheckFlag) {
            if (multiCheckFlag) {
                //处理新增部门员工
                const staffCodes = [];
                const staffTypes = [];
                selectedStaffArr.forEach((i) => {
                    staffCodes.push(i.code);
                    staffTypes.push(i.type);
                });
                const param = {
                    deptCode: this.deptCheckedRow.code,
                    staffCodes: staffCodes,
                    staffTypes: staffTypes,
                };
                UrlUtils.PatchRemote(this, this.url.allotStaff, param, null, () => {
                    this.$message.success('保存成功');
                    this.handleQueryRelatedStaff();
                });
            } else {
                if (selectedStaffArr[0]) {
                    //处理修改部门联系人
                    this.handlePatchDeptLinkman(selectedStaffArr.shift());
                }
            }
        },
        handlePatchDeptLinkman(staff) {
            //删除部门联系人 修改部门联系人
            const param = {
                type: this.deptCheckedRow.type,
                code: this.deptCheckedRow.code,
                name: this.deptCheckedRow.name,
                linkmanCode: staff?.code,
                provinceCode: this.deptCheckedRow.provinceCode,
                cityCode: this.deptCheckedRow.cityCode,
                districtCode: this.deptCheckedRow.districtCode,
                address: this.deptCheckedRow.address,
            };
            UrlUtils.PatchRemote(this, this.url.patch + this.deptCheckedRow.code, param, null, (res) => {
                //刷新父组件显示数据
                this.$emit('flush');
                this.$message.success('操作成功');
                if (staff) {
                    //修改时是改为新的值
                    this.deptLinkmanArr = [
                        { linkmanName: staff.realName, linkmanTelephone: staff.mobile, code: staff.code },
                    ];
                } else {
                    //删除时显示为空
                    this.deptLinkmanArr = [];
                }
            });
        },
        queryDeptGroupAllStaffWindow(multiCheckFlag) {
            //打开子组件 true为多选框 false单选框 单选框传入部门联系人[] 多选框传入部门人员[]
            this.$refs.pickUpDeptStaff.show(
                this.deptGroupCode,
                multiCheckFlag ? this.deptStaffs : this.deptLinkmanArr,
                multiCheckFlag
            );
        },
        show(row, deptGroupCode) {
            this.deptGroupCode = deptGroupCode;
            //初始化部门联系人
            if (row.linkmanName) {
                this.deptLinkmanArr = [
                    {
                        linkmanName: row.linkmanName,
                        linkmanTelephone: row.linkmanTelephone,
                        code: row.linkmanCode,
                    },
                ];
            } else {
                this.deptLinkmanArr = [];
            }
            //记录当前选中行
            this.deptCheckedRow = row;
            //清空遗留数据 优化过度动画效果
            this.deptStaffs = [];
            //弹出弹窗
            this.visible = true;
            //查询部门人员
            this.handleQueryRelatedStaff();
        },
        handleQueryRelatedStaff() {
            //查询
            UrlUtils.QueryRemote(
                this,
                `${this.url.deptGroupDepartmentRelatedStaffList}?deptCode=${this.deptCheckedRow.code}&deptGroupCode=${this.deptGroupCode}`,
                (rst) => {
                    this.deptStaffs = rst;
                }
            );
        },
    },
};
</script>
